<style lang="less">
.statistics {
  .left {
    color: #000000;
    font-family: PingFang SC;
    background: #ffffff;
    padding: 3vh;
    .title {
      font-weight: 500;
      font-size: 2.4vh;
    }
    .title1 {
      font-weight: 500;
      font-size: 1.8vh;
      margin-top: 4.2vh;
    }
    .title2 {
      font-size: 1.6vh;
      margin-top: 2.4vh;
    }
    .title3 {
      font-size: 1.6vh;
      margin-top: 1.4vh;
      .title3Num {
        color: #2d8cf0;
      }
    }
    .line {
      margin-top: 4vh;
      border: 1px solid #e0e0e0;
    }
  }
  .right {
    border-left: 3vh solid #f8f9fb;
    color: #000000;
    font-family: PingFang SC;
    .top {
      padding: 3vh;
      background: #ffffff;
      .title {
        font-weight: 500;
        font-size: 2.4vh;
      }
      .cardDiv {
        margin-top: 2.7vh;
        .card1 {
          background: linear-gradient(
            108.39deg,
            #4699ff 11.21%,
            #5abaff 100.02%
          );
        }
        .card2 {
          background: linear-gradient(
            108.39deg,
            #4dc8d4 11.21%,
            #71d2a5 100.02%
          );
        }
        .card3 {
          background: linear-gradient(
            108.39deg,
            #f5916e 11.21%,
            #fbac68 100.02%
          );
        }
        .card4 {
          background: linear-gradient(
            108.39deg,
            #8791f0 11.21%,
            #c6b0fb 100.02%
          );
        }
        .card {
          width: 23%;
          height: 14vh;
          border-radius: 20px;
          padding: 3.4vh;
          line-height: 1;
          .cardImg {
            margin-right: 3.2vh;
            height: 7vh;
            width: 7vh;
          }
          .cardNum {
            font-weight: 600;
            font-size: 4vh;
            color: #ffffff;
          }
          .cardLabel {
            margin-top: 0.8vh;
            font-size: 1.8vh;
            color: #ffffff;
          }
        }
      }
    }
    .bottom {
      border-top: 3vh solid #f8f9fb;
      .payDiv {
        text-align: center;
        padding: 3vh;
        background: #ffffff;
        height: 56vh;
        .title {
          font-weight: 500;
          font-size: 2.4vh;
        }
        .payDivImg {
          margin-top: 5.4vh;
        }
        .payDivNum {
          font-weight: 500;
          font-size: 4vh;
        }
        .payColor1 {
          color: #09bb07;
          span {
            font-size: 2.4vh;
          }
        }
        .payColor2 {
          color: #2d8cf0;
          span {
            font-size: 2.4vh;
          }
        }
        .payDivText {
          font-size: 1.6vh;
        }
        .line {
          margin-top: 4vh;
          border: 1px solid #e0e0e0;
        }
      }
      .tableDiv {
        padding: 3vh;
        background: #ffffff;
        // border-left: 0vh solid #f8f9fb;
        .title {
          font-weight: 500;
          font-size: 2.4vh;
          margin-bottom: 3vh;
        }
        .ivu-table {
          font-size: 1.6vh;
          td {
            height: 5vh;
          }
        }
      }
    }
  }
}
</style>
<template>
  <div class="statistics">
    <Row>
      <Col span="4" class="left">
      <div class="title">运营数据</div>
      <div class="title1">全部订单</div>
      <div class="title2">订单数量：{{dataObj.allCount}}</div>
      <div class="title3">销售金额：<span class="title3Num">¥{{dataObj.allMoney}}</span></div>
      <div class="line"></div>
      <div class="title1">今日</div>
      <div class="title2">订单数量：{{dataObj.todayCount}}</div>
      <div class="title3">销售金额：<span class="title3Num">¥{{dataObj.todayMoney}}</span></div>
      <div class="line"></div>
      <div class="title1">本周</div>
      <div class="title2">订单数量：{{dataObj.weekCount}}</div>
      <div class="title3">销售金额：<span class="title3Num">¥{{dataObj.weekMoney}}</span></div>
      <div class="line"></div>
      <div class="title1">本月</div>
      <div class="title2">订单数量：{{dataObj.monthCount}}</div>
      <div class="title3">销售金额：<span class="title3Num">¥{{dataObj.monthMoney}}</span></div>
      </Col>
      <Col span="20" class="right">
      <div class="top">
        <div class="title">订单状态统计</div>
        <div class="cardDiv">
          <Row type="flex" justify="space-between">
            <Col class="card card1">
            <Row type="flex" justify="start">
              <Col>
              <img class="cardImg" :src="quanbu" draggable="false" />
              </Col>
              <Col>
              <div class="cardNum">{{dataObj.status1}}</div>
              <div class="cardLabel">已付款</div>
              </Col>
            </Row>
            </Col>

            <Col class="card card2">
            <Row type="flex" justify="start">
              <Col>
              <img class="cardImg" :src="daichuli" draggable="false" />
              </Col>
              <Col>
              <div class="cardNum">{{dataObj.status2}}</div>
              <div class="cardLabel">待分账</div>
              </Col>
            </Row>
            </Col>

            <Col class="card card3">
            <Row type="flex" justify="start">
              <Col>
              <img class="cardImg" :src="daifenzhang" draggable="false" />
              </Col>
              <Col>
              <div class="cardNum">{{dataObj.status3}}</div>
              <div class="cardLabel">已完成</div>
              </Col>
            </Row>
            </Col>

            <Col class="card card4">
            <Row type="flex" justify="start">
              <Col>
              <img class="cardImg" :src="yiwancheng" draggable="false" />
              </Col>
              <Col>
              <div class="cardNum">{{dataObj.status101}}</div>
              <div class="cardLabel">已退款</div>
              </Col>
            </Row>
            </Col>
          </Row>
        </div>
      </div>
      <div class="bottom">
        <Row>
          <!-- <Col span="6" class="payDiv">
          <div class="title">支付方式统计</div>
          <img class="payDivImg" :src="weixinzhifu" draggable="false" />
          <div class="payDivNum payColor1">{{dataObj.onlinePayCount}}<span>({{dataObj.onlinePayPercent}}%)</span></div>
          <div class="payDivText">线上支付</div>
          <div class="line"></div>
          <img class="payDivImg" :src="xianxiazhifu" draggable="false" />
          <div class="payDivNum payColor2">{{dataObj.offlinePayCount}}<span>({{dataObj.offlinePayPercent}}%)</span></div>
          <div class="payDivText">线下支付</div>
          </Col> -->
          <Col span="24" class="tableDiv">
          <div class="title">商品销量排行
            <RadioGroup v-model="topType" type="button" size="small" @on-change="radioGroupChange">
              <Radio :label="1">全部</Radio>
              <Radio :label="2">本日</Radio>
              <Radio :label="3">本周</Radio>
              <Radio :label="4">本月</Radio>
            </RadioGroup>
          </div>
          <Table :columns="columns" :data="data">
            <template slot-scope="{ row, index }" slot="type">
              <span v-if="row.type==item.id" v-for="item,index in orderTypeList" :key="index">{{item.name}}</span>
            </template>
          </Table>

          </Col>
        </Row>

      </div>
      </Col>
    </Row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      quanbu: require("../../assets/icon-quanbu.png"),
      daichuli: require("../../assets/icon-daichuli.png"),
      daifenzhang: require("../../assets/icon-daifenzhang.png"),
      yiwancheng: require("../../assets/icon-yiwancheng.png"),
      weixinzhifu: require("../../assets/icon-weixinzhifu.png"),
      xianxiazhifu: require("../../assets/icon-xianxiazhifu.png"),
      MyUtil: this.MyUtil,
      orderTypeList: [
        { id: 1, name: "实物" },
        { id: 2, name: "活动" },
      ],
      dataObj: [],
      topType: 1,
      data: [],
      columns: [
        {
          title: "名称",
          key: "name",
          ellipsis: true,
          tooltip: true,
        },
        {
          title: "规格",
          key: "standard",
          ellipsis: true,
          tooltip: true,
        },
        {
          title: "商家",
          key: "nickname",
          ellipsis: true,
          tooltip: true,
        },
        {
          title: "电话",
          key: "phone",
          ellipsis: true,
          tooltip: true,
        },
        {
          title: "类型",
          slot: "type",
        },
        {
          title: "销量",
          key: "buyCount",
        },
      ],
    };
  },
  methods: {
    getDataObj() {
      this.MyUtil.ajax()
        .post("/OrderController/orderStatisticsForWeb", {})
        .then((resData) => {
          this.dataObj = resData;
          this.radioGroupChange();
        });
    },
    radioGroupChange() {
      if (this.dataObj) {
        if (this.topType == 1) {
          this.data = this.dataObj.allTopList;
        }
        if (this.topType == 2) {
          this.data = this.dataObj.todayTopList;
        }
        if (this.topType == 3) {
          this.data = this.dataObj.weekTopList;
        }
        if (this.topType == 4) {
          this.data = this.dataObj.monthTopList;
        }
      }
    },
  },
  created() {
    this.getDataObj();
  },
};
</script>
